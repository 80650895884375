<template>
    <v-row class="align-center text-center">
        <v-col cols="12" class="text-center mt-16 pt-16"><h1>Welcome</h1></v-col>
        <v-col cols="1" sm="2" md="4" />
        <v-col cols="10" sm="8" md="4" x-lg="2">
            <v-form autocomplete="off" novalidate="novalidate">
                <v-row>
                    <v-col cols="12" class="text-center"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="Nova senha:" type="password" v-model="usuario.nsenha" @keyup.enter="novaSenha" autocomplete="new-password" /></v-col>
                    <v-col cols="12" class="text-center"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="Repita a senha:" type="password" v-model="usuario.rsenha" @keyup.enter="novaSenha" autocomplete="new-password" /></v-col>
                </v-row>
            </v-form>
            <v-alert class="mt-7" v-if="erro" type="error" style="text-align: left">{{erro}}</v-alert>
            <v-alert class="mt-7" v-if="sucesso" type="success" style="text-align: left">{{sucesso}}</v-alert>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-btn :loading="carregando" @click="$router.push('/login')" color="default" rounded x-large class="mr-4"><v-icon left>mdi-arrow-left</v-icon>Cancelar</v-btn>
            <v-btn :loading="carregando" @click="novaSenha" color="secondary" rounded x-large><v-icon left>mdi-lock</v-icon>Alterar senha</v-btn>
        </v-col>
        <v-col cols="12" style="background-color: #005FAB;" class="mt-8 py-16">
            <v-img width="80%" style="margin:0 auto; max-width: 500px;" :src="require(`@/assets/img/logo-amarela.svg`)" alt="" />
        </v-col>
    </v-row>
</template>

<script>

    import axios from 'axios'
    import {mapState} from 'vuex'

    export default {
        name: "LoginNovaSenha",
        data() {
            return {
                usuario : {},
                sucesso : null,
                erro : null,
                carregando : false
            }
        },
        computed : {
            ...mapState(['apiUrl'])
        },
        methods : {
            novaSenha() {
                this.carregando = true
                axios.post(`${this.apiUrl}login/senha/alterar`, {
                    ...this.usuario,
                    id_usuario : parseInt(this.$router.currentRoute.params.id_usuario),
                    token      : this.$router.currentRoute.params.token
                }).then(() => {
                    this.erro       = null
                    this.sucesso    = 'Operação realizada com sucesso, você será direcionado para a tela de login em 10 segundos.'
                    this.carregando = false
                    // Direcionar o usuário para a tela de login após 10 segundos
                    setTimeout(() => {
                        this.sucesso = null
                        this.$router.push('/login')
                    }, 10 * 1000)
                }).catch(e => {
                    this.erro       = this.erroFormatar(e)
                    this.sucesso    = null
                    this.carregando = false
                })
            }
        }
    }
</script>

<style scoped>
    h1 {color:#005FAB; font-size:54px; text-align: center;}
</style>
