<template>
    <v-dialog transition="scroll-y-transition" v-model="dialog.livroDialog.exibir" max-width="1920px" :overlay-opacity="0.1" scrollable fullscreen>
        <v-card>
            <v-card-actions style="background-color: #016FC7;">
                <v-spacer/>
                <v-btn dark v-if="livro.epub" icon @click="paginaMenos" fab small><v-icon>mdi-arrow-left-circle-outline</v-icon></v-btn>
                <v-btn dark v-if="livro.epub" icon @click="paginaMais" fab small><v-icon>mdi-arrow-right-circle-outline</v-icon></v-btn>
                <v-btn dark v-if="livro.epub" icon @click="zoom = zoom - 0.05; onResize();" fab small><v-icon>mdi-format-font-size-decrease</v-icon></v-btn>
                <v-btn dark v-if="livro.epub" icon @click="zoom = zoom + 0.05; onResize();" fab small><v-icon>mdi-format-font-size-increase</v-icon></v-btn>
                <v-spacer/>
                <v-btn dark text @click="dialogOcultar({name : 'livroDialog', update : false})"><v-icon left>mdi-close</v-icon> fechar livro</v-btn>
            </v-card-actions>
            <v-divider/>
            <v-card-text class="pa-0" style="text-align: center; background-color: #333333;">
                <template v-if="livro === null">
                    <v-alert dark>Livro não localizado</v-alert>
                </template>
                <template v-else>
                    <!-- Telas pequenas
                    <iframe id="frame" class="hidden-md-and-up" :style="styles" style="width: 100%; height: 85vh; display: block;" frameborder=0 :src="livro.link"></iframe>
                     Telas médias
                    <iframe id="frame" class="hidden-lg-and-up hidden-sm-and-down" :style="styles" style="width: 100%; height: 93vh; display: block;" frameborder=0 :src="livro.link"></iframe>
                     Telas grandes -->
                    <iframe id="frame" :style="`
                    background-color: #FFFFFF;
                    width:1191px;
                    height:842px;
                    margin:0 auto;
                    -ms-zoom: ${zoom};
                    -moz-transform: scale(${zoom});
                    -moz-transform-origin: 0 0;
                    -o-transform: scale(${zoom});
                    -o-transform-origin: 0 0;
                    -webkit-transform: scale(${zoom});
                    -webkit-transform-origin: 0 0;`" style="display: block;" frameborder="0" :src="livro.link"></iframe>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import axios from "axios"
import {mapMutations, mapState} from "vuex"

export default {
    name: "LivroDialog",
    components: {},
    props : ['id_conteudo', 'id_curso'],
    data() {
        return {
            windowSize: {
                x: 0,
                y: 0,
            },
            tab : null,
            livro : {},
            pagina : 0,
            zoom : 1
        }
    },
    computed : {
        ...mapState(['apiUrl', 'usuarioSessao', 'dialog'])
    },
    methods : {
        ...mapMutations(['dialogOcultar']),
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        get() {
            let id_level = null
            if (this.usuarioSessao.id_level) {
                id_level = this.usuarioSessao.id_level
            }
            return axios.post(`${this.apiUrl}livro/get`, {id_curso : parseInt(this.dialog.livroDialog.id_curso), id_conteudo : parseInt(this.dialog.livroDialog.id_conteudo), id_level}).then( (res) => {
                if(res.data.epub) {
                    this.livro = {
                        ...res.data,
                        link : `${this.apiUrl}livro/conteudo/${this.dialog.livroDialog.id_curso}/${this.dialog.livroDialog.id_conteudo}/${res.data.id_level}/${res.data.indice[0]}`
                    }
                } else {
                    this.livro = res.data
                }
            })
        },
        atualizarPagina() {
            this.livro.link = `${this.apiUrl}livro/conteudo/${this.dialog.livroDialog.id_curso}/${this.dialog.livroDialog.id_conteudo}/${this.livro.id_level}/${this.livro.indice[this.pagina]}`
        },
        paginaMais() {
            if (this.pagina + 1 < this.livro.indice.length) {
                this.pagina++
                this.atualizarPagina()
            }
        },
        paginaMenos() {
            if (this.pagina > 0) {
                this.pagina--
                this.atualizarPagina()
            }
        }
    },
    watch : {
        'dialog.livroDialog.exibir' : function(e) {
            if(e) {
                this.zoom = 1
                this.pagina = 0
                this.onResize()
                this.get()
            }
        }
    }
}
</script>

<style scoped>

</style>