import Vue from 'vue'
import store from "./store";
import router from './router'
import {globalMixin} from '@/globalMixin';
import App from './App.vue'
import VueAuthImage from 'vue-auth-image';
import vuetify from './plugins/vuetify';
import "./axios";

Vue.config.productionTip = false
Vue.mixin(globalMixin);
Vue.use(VueAuthImage);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
