import store from './store'
import axios from 'axios'

export const globalMixin = {
    data() {
        return {
            config : {}
        }
    },
    methods: {
        async consultarCep(cep) {
            return axios.post(`${store.state.baseUrl + store.state.idEmpresaThor}/endereco/consultar_cep`, {cep : cep.match(/[0-9]*/g).join('')}).then((res) => {
                return res.data;
            });
        },
        erroFormatar(e) {
            return e.response.data.split(';').join("<br />")
        },
        mascaraRemover(valor) {
            if(valor == undefined || valor == null) {
                valor = '';
            }
            return valor.match(/[0-9]*/g).join('');
        },
        porcentagem(valor_maior, valor_menor) {
            return this.numero((100 * valor_menor) / valor_maior, 1);
        },
        porcentagem_cor(porcentagem) {
            if(porcentagem >= 90) {
                return 'danger';
            } else if(porcentagem >= 75) {
                return 'warning';
            } else {
                return 'success';
            }
        },
        textoDestacar(texto, busca) {
            if(busca == null || busca == undefined || texto == null) {
                return null
            }
            const rg = RegExp(busca, 'gi')
            return (texto.search(rg) > 0) ? texto : null;
        },
        arredondar_armazenamento(size){
            let i = 0;
            let iec = ["B", "KB", "MB", "GB", "TB"];
            while ((size/1024) >= 1) {
                size=size/1024;
                i++;
            }
            return(this.numero(size,1) + " " + iec[i]);
        },
        wordLimiter : function (str, limit) {
            if(str == null) {
                return null;
            }
            str = str.split(' ');
            let saida = '';
            if(str.length > limit) {
                let i;
                for(i = 0 ; i < limit ; i++) {
                    if(i > 0) {
                        saida += ' ';
                    }
                    saida += str[i];
                }
                saida += '...';
            } else {
                saida = str.join(' ');
            }
            return saida;
        },
        removerAcentos : function ( newStringComAcento ) {
            let string = newStringComAcento;
            let mapaAcentosHex = {
                a : /[\xE0-\xE6]/g,
                A : /[\xC0-\xC6]/g,
                e : /[\xE8-\xEB]/g,
                E : /[\xC8-\xCB]/g,
                i : /[\xEC-\xEF]/g,
                I : /[\xCC-\xCF]/g,
                o : /[\xF2-\xF6]/g,
                O : /[\xD2-\xD6]/g,
                u : /[\xF9-\xFC]/g,
                U : /[\xD9-\xDC]/g,
                c : /\xE7/g,
                C : /\xC7/g,
                n : /\xF1/g,
                N : /\xD1/g,
            };
            for ( let letra in mapaAcentosHex ) {
                let expressaoRegular = mapaAcentosHex[letra];
                string = string.replace( expressaoRegular, letra );
            }
            return string;
        },
        permissao(permissao) {
            if(permissao) {
                return true;
            } else {
                return false;
            }
            /*
            if(!Array.isArray(permissao)) {
                permissao = [permissao];
            }
            for(let i = 0 ; i < permissao.length ; i++) {
                let perm = store.state.usuarioSessao.permissoes.find((v) => {
                    return v.id_permissao == permissao[i];
                });
                if(perm != undefined) {

                    return true
                }
            }
            return false*/
        },
        dataPt(data) {
            if(data == undefined || data == '' || data == null) {
                return null
            }
            if(data.length == 10 || data.length == 7) {
                return data.split('-').reverse().join('/')
            }
            else if(data.indexOf('T') > -1) {
                let d = data.split('T')
                let dd = d[0].split('-')
                return d[1] + ' ' + dd[2] + '/' + dd[1] + '/' +dd[0]
            }
            else if(data.length >= 19) {
                let d = data.split(' ')[0]
                let h = data.split(' ')[1]
                return h.substring(0, 8) + ' ' + d.split('-').reverse().join('/')
            }
            else if(data.length == 4) {
                return data
            }
            return null
        },
        dataEn(data) {
            if(data == undefined || data == '' || data == null) {
                return null;
            }
            return data.split('/').reverse().join('-');
        },
        dataEnToHour(data) {
            if(data == undefined || data == '' || data == null) {
                return null;
            }
            return (data.split(' ')[1]).split('.')[0]
        },
        idade(data_nascimento_en) {
            let d = new Date,
                ano_atual = d.getFullYear(),
                mes_atual = d.getMonth() + 1,
                dia_atual = d.getDate(),
                data_nascimento = data_nascimento_en.split('-'),
                ano_aniversario = +data_nascimento[0],
                mes_aniversario = +data_nascimento[1],
                dia_aniversario = +data_nascimento[2],
                quantos_anos    = ano_atual - ano_aniversario;
            if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
                quantos_anos--;
            }
            return quantos_anos < 0 ? 0 : quantos_anos;
        },
        numero(numero, casas_decimais) {
            if(isNaN(numero)) {
                return '0,00'
            }
            if(numero == 0) {
                return '0,00'
            }
            if(numero === null || numero === '') {
                return null;
            }
            if(casas_decimais == undefined) {
                casas_decimais = 2;
            }
            return parseFloat(numero).toLocaleString('pt-BR', {minimumFractionDigits : casas_decimais, maximumFractionDigits : casas_decimais});
        },
        number(numero, casas_decimais) {
            if(numero == 0) {
                return '0.00'
            }
            if(numero === null || numero === '' || numero == undefined) {
                return null;
            }
            numero = numero.replace('.', '');
            numero = numero.replace(',', '.');
            if(casas_decimais == undefined) {
                casas_decimais = 2;
            }
            return parseFloat(numero).toFixed(casas_decimais);
        },
        storageRead(key) {
            return JSON.parse(localStorage.getItem(key))
        },
        storageSave(key, data) {
            return localStorage.setItem(key, JSON.stringify(data))
        }
    }
};