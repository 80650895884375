<template>
    <v-row class="align-center text-center">
        <v-col cols="12" class="text-center mt-16 pt-16"><h1>Welcome</h1></v-col>
        <v-col cols="1" sm="2" md="4"/>
        <v-col cols="10" sm="8" md="4" x-lg="2">
            <v-form>
                <v-row>
                    <v-col cols="12" class="text-center"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="E-mail:" type="email" v-model="usuario.email" @keyup.enter="logar" /></v-col>
                    <v-col v-if="!recuperar_senha" cols="12" class="text-center"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="Senha:" type="password" v-model="usuario.senha" @keyup.enter="logar" /></v-col>
                </v-row>
            </v-form>
            <v-alert class="mt-7" v-if="erro" type="error" style="text-align: left">{{erro}}</v-alert>
            <v-alert class="mt-7" v-if="sucesso" type="success" style="text-align: left">{{sucesso}}</v-alert>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-btn :loading="carregando" v-if="recuperar_senha" @click="recuperar_senha = false" text rounded large class="mr-2"><v-icon left>mdi-arrow-left</v-icon>Cancelar</v-btn>
            <v-btn :loading="carregando" @click="recuperarSenha" :text="!recuperar_senha" :color="recuperar_senha ? 'primary' : null" rounded elevation="0"><v-icon left>mdi-lock</v-icon>Recuperar senha</v-btn>
            <v-btn :loading="carregando" v-if="!recuperar_senha" @click="logar" color="primary" rounded class="ml-2" elevation="0"><v-icon left>mdi-login</v-icon>Login</v-btn>
        </v-col>
        <v-col cols="1" sm="2" md="4"/>
        <v-col cols="10" sm="8" md="4" x-lg="2" class="py-0">
            <v-divider/>
        </v-col>
        <v-col cols="12" class="py-0">
            <v-btn @click="dialogCadastro = true" rounded color="primary" class="mt-4" elevation="0"><v-icon left>mdi-plus</v-icon>Cadastre-se</v-btn>
            <v-dialog v-model="dialogCadastro" max-width="1000" scrollable>
                <v-card>
                    <v-card-title>Cadastre-se</v-card-title>
                    <v-card-subtitle>Se você ainda não possui uma conta, preencha o formulário abaixo para se cadastrar.</v-card-subtitle>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col cols="12" sm="6" md="8"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Nome completo:" v-model="cadastro.nome_completo" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* CPF:" v-model="cadastro.cpf" v-mask="'###.###.###-##'" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Celular:" v-model="cadastro.celular" v-mask="['(##)  ####-####', '(##) #.####-####']" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* CEP:" v-model="cadastro.cep" v-mask="'#####-###'" /></v-col>
                                <v-col cols="12" sm="6" md="4"><SelectEstado background-color="#FFFFFF" color="#333333" :outlined="true" :rounded="true" :hide-details="true" label="* Estado:" v-model="cadastro.id_estado" /></v-col>
                                <v-col cols="12" sm="6" md="4"><SelectCidade background-color="#FFFFFF" color="#333333" :outlined="true" :rounded="true" :id_estado="cadastro.id_estado" label="* Cidade:" v-model="cadastro.id_cidade" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Bairro:" v-model="cadastro.bairro" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Logradouro:" v-model="cadastro.logradouro" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="Número:" v-model="cadastro.numero" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="Complemento:" v-model="cadastro.complemento" /></v-col>
                                <v-col cols="12" sm="6" md="4"><SelectFranquia background-color="#FFFFFF" color="#333333" :outlined="true" :rounded="true" :hide-details="true" label="* Escola:" v-model="cadastro.id_franquia" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* E-mail:" v-model="cadastro.email" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field type="password" background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Senha:" v-model="cadastro.senha" /></v-col>
                                <v-col cols="12" sm="6" md="4"><v-text-field type="password" background-color="#FFFFFF" color="#333333" outlined rounded hide-details label="* Confirme a senha:" v-model="cadastro.rsenha" /></v-col>
                            </v-row>
                        </v-form>
                        <v-alert class="mt-7" v-if="erro" type="error" style="text-align: left" v-html="erro"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="dialogCadastro = false" rounded text><v-icon left>mdi-close</v-icon>Cancelar cadastro</v-btn>
                        <v-btn @click="cadastrar" color="primary" rounded elevation="0"><v-icon left>mdi-content-save</v-icon>Cadastrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
        <v-col cols="12" style="background-color: #005FAB;" class="mt-8 py-16">
            <v-img width="80%" style="margin:0 auto; max-width: 500px;" :src="require(`@/assets/img/logo-amarela.svg`)" alt="" />
        </v-col>
    </v-row>
</template>

<script>

    import axios from 'axios'
    import {mask} from 'vue-the-mask'
    import {mapState} from 'vuex'
    import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";
    import SelectEstado from "@/Views/Estrutura/Form/SelectEstado.vue";
    import SelectCidade from "@/Views/Estrutura/Form/SelectCidade.vue";

    export default {
        name: "Login",
        components: {SelectCidade, SelectEstado, SelectFranquia},
        directives : {mask},
        data() {
            return {
                usuario : {},
                cadastro : {},
                sucesso : null,
                recuperar_senha : false,
                erro : null,
                carregando : false,
                dialogCadastro : false
            }
        },
        computed : {
            ...mapState(['apiUrl', 'usuarioChave'])
        },
        methods : {
            logar() {
                axios.post(`${this.apiUrl}login`, this.usuario).then(res => {
                    /*if (res.data.id_grupo === 5 && res.data.escola.filter((v) => {return v.ativo}).length === 0) {
                        this.erro = 'Seu cadastro de aluno está inativo, entre em contato com a sua escola para mais informações.'
                        return
                    }*/
                    let r = {...res.data, embed : false}
                    this.$store.commit('usuarioLogin', r)
                    localStorage.setItem(this.usuarioChave, JSON.stringify(r))
                    this.erro = null
                    this.$router.push('/')
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                    this.carregando = false
                })
            },
            recuperarSenha() {
                if(this.recuperar_senha) {
                    this.carregando = true
                    axios.post(`${this.apiUrl}login/senha/recuperar`, this.usuario).then(() => {
                        this.erro       = null
                        this.sucesso    = 'Operação realizada com sucesso, siga as instruções contidas no e-mail.'
                        this.carregando = false
                        // Direcionar o usuário para a tela de login após 10 segundos
                        setTimeout(() => {
                            this.recuperar_senha = false
                            this.sucesso = null
                        }, 15 * 1000)
                    })
                } else {
                    this.recuperar_senha = true
                }
            },
            cadastrar() {
                axios.post(`${this.apiUrl}login/cadastrar`, {
                    ...this.cadastro,
                    cpf     : this.mascaraRemover(this.cadastro.cpf),
                    celular : this.mascaraRemover(this.cadastro.celular),
                    cep     : this.mascaraRemover(this.cadastro.cep)
                }).then(() => {
                    this.erro       = null
                    this.carregando = false
                    this.usuario = {
                        email : this.cadastro.email,
                        senha : this.cadastro.senha
                    }
                    this.cadastro = {}
                    this.dialogCadastro = false
                    this.logar()
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                    this.carregando = false
                })
            },
        }
    }
</script>

<style scoped>
h1 {color:#005FAB; font-size:54px; text-align: center;}
</style>
