<template>
    <v-app v-if="usuarioLogado">
        <v-btn fixed bottom left class="mt-2 hidden-md-and-down" elevation="2" @click="drawer = true" v-if="!drawer" fab><v-icon>mdi-menu</v-icon></v-btn>
        <v-navigation-drawer v-model="drawer" app color="#005FAB">
            <v-list shaped dense dark>
                <v-list-item>
                    <v-btn class="mr-2" icon @click="drawer = false"><v-icon>mdi-menu</v-icon></v-btn>
                    <v-img width="85%" :src="require(`@/assets/img/logo-amarela.svg`)" alt="" />
                </v-list-item>
                <v-divider style="margin:8px 0;"/>
                <template v-for="item in menuPrincipal">
                    <v-list-item exact :to="item.router" v-if="item.router" link :key="item.title">
                        <v-list-item-icon class="mr-4">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group no-action v-else :key="item.title">
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-4">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{item.title}}</v-list-item-title>
                        </template>
                        <v-list-item exact :to="itemSub.router" v-for="(itemSub, i) in item.itens" :key="i" link>
                            <v-list-item-title v-text="itemSub.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </template>
                <v-list-item exact @click="sair" link>
                    <v-list-item-icon class="mr-4">
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main class="pt-0" :style="{backgroundColor : backgroundColor}">
            <Dialogs/>
            <v-bottom-navigation class="hidden-lg-and-up" fixed grow v-model="bottomNavigationSelected" :background-color="backgroundColor">
                <template v-for="(m, i) in menuPrincipal">
                    <v-btn v-if="m.showMobile" :color="m.backgroundColor" :to="m.router" :key="i" style="font-weight: bold;">
                        <span :style="{color:m.color}">{{m.title}}</span>
                        <v-icon :style="{color:m.color}">{{m.icon}}</v-icon>
                    </v-btn>
                </template>
            </v-bottom-navigation>
            <v-container fluid class="pa-0 pb-16">
                <keep-alive>
                    <router-view @changeBackgroundColor="backgroundColor" />
                </keep-alive>
            </v-container>
        </v-main>
    </v-app>
    <v-app v-else style="background-color: #00436F">
        <v-container fluid class="bg-login">
            <LoginNovaSenha v-if="$route.path.indexOf('/login/recuperar_senha') > -1"/>
            <Login v-else />
        </v-container>
    </v-app>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";
import Login from "@/Views/Login/Login";
import Dialogs from "@/Views/Estrutura/Dialogs"
import LoginNovaSenha from "@/Views/Login/LoginNovaSenha.vue";
import {StatusBar} from '@capacitor/status-bar';

export default {
    name: 'App',

    components: {
        LoginNovaSenha,
        Dialogs,
        Login
    },
    computed : {
        ...mapState(['usuarioLogado', 'usuarioSessao', 'usuarioChave', 'baseUrl', 'apiUrl'])
    },
    methods : {
        sair() {
            axios.post(`${this.apiUrl}login/sair`, {authorization : this.usuarioSessao.sessao_token}).then(() => {
                this.drawer = false
                this.$store.commit('usuarioLogin', null)
                localStorage.clear()
                delete axios.defaults.headers.common['authorization']
                this.erro = null
                if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                    this.$router.push('/login')
                }
            })
        },
        async validarToken() {
            const json = localStorage.getItem(this.usuarioChave);
            const sessao = JSON.parse(json);
            if (!sessao) {
                this.$store.commit('usuarioLogin', null);
                if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                    return this.$router.push('/login')
                }
                return
            }
            await axios.post(`${this.apiUrl}login/authorization/validar`, {authorization : sessao.sessao_token}).then((res) => {
                if(res.data) {
                    this.$store.commit('usuarioLogin', sessao);
                } else {
                    localStorage.removeItem(this.usuarioChave);
                    if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                        return this.$router.push('/login')
                    }
                }
            })
        },
        async getMenuPrincipal() {
            this.menuPrincipal = [
                {title: 'Perfil', icon: 'mdi-account-circle-outline', router: '/', backgroundColor: '#FFD600', color: '#005FAB', showMobile : true},
                {title: 'Cursos', icon: 'mdi-star-outline', router: '/cursos', backgroundColor: '#005FAB', color: '#FFD600', showMobile : true},
                {title: 'Aula', icon: 'mdi-comment-outline', router: '/curso/aula_atual', backgroundColor: '#00436F', color: '#FFD600', showMobile : true},
            ]
            //if(['1', '2', '4'].includes(this.usuarioSessao.id_grupo)) {
                this.menuPrincipal = this.menuPrincipal.concat([
                    {title: 'Buddy', icon: 'mdi-forum-outline', router: '/assistente', backgroundColor: '#005FAB', color: '#FFD600', showMobile : true}
                ])
            //}
            this.menuPrincipal = this.menuPrincipal.concat([
                {title: 'Tradutor', icon: 'mdi-translate', router: '/tradutor', backgroundColor: '#FFFFFF', color: '#005FAB', showMobile : true},
                {title: 'YouGlish', icon: 'mdi-flag-outline', router: '/youglish', backgroundColor: '#FFFFFF', color: '#005FAB', showMobile : false},
                /*{title: 'Financeiro', icon: 'mdi-currency-usd', router: '/financeiro', backgroundColor: '#D2D1D1', color: '#FFFFFF'},*/
            ])
        }
    },
    data: () => ({
        drawer : null,
        menuPrincipal : [],
        bottomNavigationSelected : '/',
        backgroundColor : '#FFFFFF'
    }),
    watch : {
        usuarioLogado : async function() {
            await this.validarToken()
            await this.getMenuPrincipal()
        },
        '$route' (to){
            this.title = to.meta.title.replace(' | Excellent Global - Aluno', '') || 'Excellent Global - Aluno'
            this.backgroundColor = to.meta.backgroundColor
        }
    },
    async created() {
        await this.validarToken()
        await StatusBar.setBackgroundColor({color: '#016FC7'}).catch(()=>{})
    }
};
</script>

<style>
    * {font-family: Montserrat;}
    .bg-login {background-image: url('~@/assets/img/bg-login.png'); background-color:#00436F; background-size: 100% ;}
</style>