import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiUrl : 'https://excellentglobal-aluno-api-whiyyqnxmq-rj.a.run.app/', // API Golang // 'https://excellentglobal-aluno-api-whiyyqnxmq-rj.a.run.app/' // 'http://localhost:82/'
        axiosWaitTimeout : 350, // tempo em milissegundos
        baseUrl : 'https://excellentglobal-api-whiyyqnxmq-rj.a.run.app/', // API PHP 'https://excellentglobal-api-whiyyqnxmq-rj.a.run.app/' // 'http://localhost/'
        dialog: {
            // Livro
            livroDialog : {exibir : false}
        },
        paginationLimit : 20,
        paginationLimitGerenciar : 10,
        usuarioLogado : false,
        usuarioChave : 'excellentglobal',
        usuarioSessao : {},
    },
    mutations : {
        usuarioLogin(state, usuario) {
            state.usuarioSessao = usuario
            if (usuario) {
                axios.defaults.headers.common['authorization'] = usuario.sessao_token;
                state.usuarioLogado = true
            } else {
                delete axios.defaults.headers.common['authorization'];
                state.usuarioLogado = false
            }
        },
        dialogExibir(state, prop) {
            state.dialog[prop.name] = {
                ...prop,
                update: prop.update != undefined ? state.dialog[prop.name].update : 0,
                exibir: true
            }
        },
        dialogOcultar(state, prop) {
            state.dialog[prop.name] = {
                ...state.dialog[prop.name],
                update: prop.update != undefined && prop.update ? state.dialog[prop.name].update + 1 : state.dialog[prop.name].update,
                exibir: false
            }
        }
    },
    actions: {
    }
})
