<template>
    <div>
        <!-- Configuração -->
        <LivroDialog/>
    </div>
</template>

<script>

import LivroDialog from "@/Views/Livro/LivroDialog"

export default {
    name: "Dialogs",
    components: {
        LivroDialog
    }
}
</script>

<style scoped>

</style>