<template>
    <v-autocomplete :dense="dense" :hide-details="hideDetails" :search-input.sync="selectSync" :outlined="outlined" :rounded="rounded" :label="label" :disabled="carregando || disabled" :items="items" v-model="id_registro" />
</template>

<script>
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "SelectFranquia",
    props : ['dense', 'hideDetails', 'outlined', 'label', 'disabled', 'value', 'rounded'],
    data() {
        return {
            id_registro : null,
            selectSync : '',
            items : [],
            paginarWait : null,
            carregando : false
        }
    },
    computed : {
        ...mapState(['apiUrl', 'axiosWaitTimeout'])
    },
    methods : {
        selectListar(b) {
            // Evita que seja enviadas múltiplas requisições desnecessárias para a API
            if(this.items.filter((v) => {
                return v.text === b
            }).length > 0) {
                return
            }
            return axios.post(`${this.apiUrl}franquia/listar`, {busca : b, id_franquia : this.id_registro}).then((res) => {
                this.items = res.data.lista.map((rt) => {
                    return {value : rt.id_franquia, text : rt.franquia}
                })
            })
        }
    },
    watch : {
        selectSync(b) {
            clearTimeout(this.paginarWait)
            this.paginarWait = setTimeout(() => {
                this.selectListar(b)
            }, this.axiosWaitTimeout)
        },
        'id_registro' : function(v) {
            this.$emit('input', v)
        },
        'value' : function(v) {
            this.id_registro = v
            this.selectListar(null)
        }
    },
    created() {
        this.id_registro = this.value
    }
}
</script>

<style scoped>

</style>