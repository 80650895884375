import store from './store'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let nameApp = ' | Excellent Global - Aluno';

function erroImportar() {
    window.location.reload()
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
      {
          path: '/',
          name: 'Perfil',
          props : true,
          component: () => import('./Views/Perfil/Perfil').catch(erroImportar),
          meta : {
              title : 'Perfil' + nameApp,
              backgroundColor : '#FFD600'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/assistente',
          name: 'Assistente',
          props : true,
          component: () => import('./Views/Assistente/Assistente').catch(erroImportar),
          meta : {
              title : 'Assistente' + nameApp,
              backgroundColor : '#005FAB'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/configuracao',
          name: 'Configuracao',
          props : true,
          component: () => import('./Views/Configuracao/Configuracao').catch(erroImportar),
          meta : {
              title : 'Configurações' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/configuracao/senha/alterar',
          name: 'ConfiguracaoSenhaAlterar',
          props : true,
          component: () => import('./Views/Configuracao/ConfiguracaoSenhaAlterar').catch(erroImportar),
          meta : {
              title : 'Configurações' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/cursos',
          name: 'Cursos',
          props : true,
          component: () => import('./Views/Cursos/Cursos').catch(erroImportar),
          meta : {
              title : 'Cursos' + nameApp,
              backgroundColor : '#016fc7'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/completo/:id_curso/:id_turma',
          name: 'CursoCompleto',
          props : true,
          component: () => import('./Views/Cursos/CursoCompleto').catch(erroImportar),
          meta : {
              title : 'Curso completo' + nameApp,
              backgroundColor : '#016fc7'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/turma/:id_curso',
          name: 'CursoTurma',
          props : true,
          component: () => import('./Views/Cursos/CursoTurma').catch(erroImportar),
          meta : {
              title : 'Curso turma' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/aulas/:id_curso/:id_turma',
          name: 'CursoAulas',
          props : true,
          component: () => import('./Views/Cursos/CursoAulas').catch(erroImportar),
          meta : {
              title : 'Curso aulas' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/aula/:id_aula',
          name: 'CursoAula',
          props : true,
          component: () => import('./Views/Cursos/CursoAula').catch(erroImportar),
          meta : {
              title : 'Curso aula' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/material/:id_curso/:id_turma',
          name: 'CursoMaterial',
          props : true,
          component: () => import('./Views/Cursos/CursoMaterial').catch(erroImportar),
          meta : {
              title : 'Curso material' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/material/conteudo/:id_turma/:id_conteudo',
          name: 'CursoMaterialConteudo',
          props : true,
          component: () => import('./Views/Cursos/CursoMaterialConteudo').catch(erroImportar),
          meta : {
              title : 'Curso revisão de conteúdo' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/material/conteudo/:id_turma/:id_conteudo/:id_conteudo_revisao',
          name: 'CursoMaterialConteudoRevisao',
          props : true,
          component: () => import('./Views/Cursos/CursoMaterialConteudo').catch(erroImportar),
          meta : {
              title : 'Curso revisão de conteúdo' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/aula_atual',
          name: 'AulaAtual',
          props : true,
          component: () => import('./Views/Cursos/CursoAulaAtual').catch(erroImportar),
          meta : {
              title : 'Aula atual' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/curso/aula/conteudo/:id_aula/:id_conteudo',
          name: 'AulaConteudo',
          props : true,
          component: () => import('./Views/Cursos/CursoAulaConteudo').catch(erroImportar),
          meta : {
              title : 'Aula' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/tradutor',
          name: 'Tradutor',
          props : true,
          component: () => import('./Views/Tradutor/Tradutor').catch(erroImportar),
          meta : {
              title : 'Tradutor' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/youglish',
          name: 'YouGlish',
          props : true,
          component: () => import('./Views/YouGlish/YouGlish').catch(erroImportar),
          meta : {
              title : 'YouGlish' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/metas',
          name: 'Metas',
          props : true,
          component: () => import('./Views/Metas/Metas').catch(erroImportar),
          meta : {
              title : 'Metas' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/learning_map/:id_curso/:id_turma',
          name: 'Learning Map',
          props : true,
          component: () => import('./Views/LearningMap/LearningMap').catch(erroImportar),
          meta : {
              title : 'Learning Map' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/livro_fisico',
          name: 'LivrosFisico',
          props : true,
          component: () => import('./Views/LivroFisico/LivroFisico').catch(erroImportar),
          meta : {
              title : 'Metas' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/livro_fisico/completo/:id_livro',
          name: 'LivrosFisicoCompleto',
          props : true,
          component: () => import('./Views/LivroFisico/LivroFisicoCompleto').catch(erroImportar),
          meta : {
              title : 'Metas' + nameApp,
              backgroundColor : '#00436F'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/financeiro',
          name: 'Financeiro',
          props : true,
          component: () => import('./Views/Financeiro/Financeiro').catch(erroImportar),
          meta : {
              title : 'Financeiro' + nameApp,
              backgroundColor : '#E5E5E5'
          },
          beforeEnter(to, from , next) {
              next()
          }
      }, {
          path: '/login',
          name: 'Login',
          props: true,
          component: () => import('./Views/Login/Login').catch(erroImportar),
          meta: {
              title: 'Login' + nameApp
          },
          beforeEnter(to, from, next) {
              next()
          }
      }, {
          path: '/login/recuperar_senha/:id_usuario/:token',
          name: 'LoginNovaSenha',
          props: true,
          component: () => import('./Views/Login/LoginNovaSenha').catch(erroImportar),
          meta: {
              title: 'Recuperar senha' + nameApp
          }
      }
  ]
});

router.beforeEach((to, from, next) => {
    // Login do professor que está dando aula pelo painel, e loga automaticamente no aplicativo
    let queryString = window.location.search
    if (queryString) {
        const urlParams = new URLSearchParams(queryString)
        if (urlParams.get('ls')) {
            let jsonStr = decodeURIComponent(escape(window.atob(urlParams.get('ls'))));
            let ls = JSON.parse(jsonStr);
            localStorage.setItem(store.state.usuarioChave, JSON.stringify(ls))
        }
    }
    document.title = to.meta.title
    next()
});

export default router;
